import axios from "axios";

const { API_URL } = process.env;

const init = () => {
  const holder = document.querySelector("main.support");
  if (!holder) return;

  holder.querySelector("form").addEventListener("submit", (e) => {
    e.preventDefault();
    const form = e.target;
    const formData = new FormData(form);
    const url = `${API_URL}/support`;
    const method = form.method;
    const headers = {
      "Content-Type": "application/json",
    };

    var data = {};
    formData.forEach((value, key) => (data[key] = value));

    axios({
      method,
      url,
      data,
      headers,
    })
      .then((res) => {
        location.href = "/thankyou/";
      })
      .catch((err) => {});
  });
};

export { init };
